<template>
<div>
    <div class="modal fade prdtmodel-nw orders-tab-main cust-prdt-table" id="item-popup" tabindex="-1" aria-labelledby="itemModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <skeleton-loader-vue v-if="!productDetail" type="string" width="50%" height="20px" />
                    <h5 v-else class="modal-title" id="itemModalLabel">
                        {{ productDetail.item_name }}
                    </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <!---------Customization Skeleton start------------->
                <div class="container" v-if="customize_skelloader">
                    <div class="product-content pt-3">
                        <div>
                            <div class="cus-title">
                                <skeleton-loader-vue type="string" width="37%" height="20px" />
                                <skeleton-loader-vue type="string" width="20%" height="20px" />
                            </div>
                            <div>
                                <div class="">
                                    <label for="radio-0-0" class="form__label-radio" style="padding-left: 0px">
                                        <skeleton-loader-vue type="circle" class="form__radio" width="20px" height="20px" style="margin-right: 20px;" />
                                        <skeleton-loader-vue type="string" width="170px" height="20px" style="position: absolute;" />
                                    </label>
                                    <span class="ch-price"><skeleton-loader-vue type="string" width="30px" height="24px" /></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!---------Customization Skeleton End------------->
                <div class="modal-body" v-if="productDetail" :id="'item-id-' + productDetail.item_id">
                    <div id="prod-details-slider" class="owl-carousel">
                        <div v-for="(slide,index) in productDetail.item_images" :key="index">
                            <div class="testimonial-item equal-height style-6" v-if="!imgError">
                                <img class="item-img" :src="slide.image_name" @error="onImgError()" />
                            </div>
                        </div>
                    </div>
                    
                    <ul class="nav nav-tabs border-0 mb-3" v-if="productDetail.is_customizable == 1 && productDetail.item_description">
                        <li class="nav-item" >
                            <a href="#sort" class="nav-link navbar-default" :class="productDetail.is_customizable != 0 ? 'active' : ''" v-if="productDetail.is_customizable != 0" data-toggle="tab">{{$t('Customization')}}</a>
                        </li>
                        <li class="nav-item"  v-if="productDetail.item_description || productDetail.custom_details.length > 0">
                            <a href="#cusines" class="nav-link navbar-default" data-toggle="tab">{{$t('Info')}}</a>
                        </li>
                    </ul>
                    <form action="#">
                    <div class="tab-content">
                        <div id="sort" class="tab-pane active tab-box" v-if="productDetail.is_customizable != 0">
                            <div class="container">
                                <div class="product-content pt-3" v-if="productDetail.is_customizable != '0' && !customize_skelloader">
                                    <div v-for="(customize, customizeIndex) in productDetail.customize_type" :key="customizeIndex">
                                        <div class="cus-title">
                                            <h6 class="cus-name">{{ customize.name }}</h6>
                                            <p v-if="customize.is_selection != 0">Maximum {{ customize.is_selection }} items</p>
                                        </div>
                                        <!--Radio Button start-->
                                        <div v-if="customize.is_min_selection == '1' && customize.is_selection == '1'"
                                        :class="{'is-invalid': $v.selectedCustomisation.customize_type['parent_'+customize.customize_type_id].value.$error}">
                                            <div class="form-check form__radio-group"
                                            v-for="(optionData, index) in customize.option" :key="index">
                                                <div data-toggle="collapse" class="rdiotogl" :href="'#collapseExample'+customizeIndex+index">
                                                    <input class="form__radio-input" type="radio" :name="'radio-' + customizeIndex" :id="'radio-' + customizeIndex + '-' + index" :value="optionData.selection_id" v-model="$v.selectedCustomisation.customize_type['parent_'+customize.customize_type_id].value.$model" @change="checkNested($event,customize.customize_type_id)" />
                                                    <label class="form__label-radio" :for="'radio-' + customizeIndex + '-' + index"><span class="form__radio-button"></span>{{ optionData.selection_name }}</label>
                                                    <span class="ch-price">
                                                        <span v-html="$auth.setting.currency"></span>
                                                        {{ optionData.selection_price }}
                                                        <b-icon-arrow-right-circle-fill v-if="optionData.options.length > 0"></b-icon-arrow-right-circle-fill>
                                                    </span>
                                                </div>
                                                <!--------Sub Customization Start Radio------------->
                                                <div class="rdiotogllist" v-if="optionData.is_min_selection == '1' && optionData.is_selection == '1' && optionData.options.length > 0 ">
                                                    <div class="chld-rdio collapse show"
                                                    :class="{'is-invalid': $v.selectedCustomisation.customize_type['parent_'+customize.customize_type_id]['child_'+optionData.selection_id].value.$error}"
                                                    v-if="optionData.options.length > 0 && selectedCustomisation.customize_type['parent_'+customize.customize_type_id].value == optionData.selection_id">
                                                        <div v-for="(subOption,subi) in optionData.options" :key="subi" class="form-check form__radio-group">
                                                            <!-- v-model="productDetail.selected_type[customizeIndex].subradio"  -->
                                                            <input class="form__radio-input" type="radio" :name="'radio-' +index+'-'+ customizeIndex" :id="'radio-' + customizeIndex + '-' +index+'-'+subi" :value="subOption.selection_id" v-model="$v.selectedCustomisation.customize_type['parent_'+customize.customize_type_id]['child_'+optionData.selection_id].value.$model" @change="checkNestedSub($event,subOption.selection_id)" />
                                                            {{subOption.is_selected}}
                                                            <label class="form__label-radio" :for="'radio-' + customizeIndex + '-' +index+'-'+subi"><span class="form__radio-button"></span>{{ subOption.selection_name }}</label>
                                                            <span class="ch-price"><span v-html="$auth.setting.currency"></span>{{ subOption.selection_price }}</span>
                                                        </div>
                                                    </div>

                                                    <div class="invalid-feedback">
                                                        <span v-if="!$v.selectedCustomisation.customize_type['parent_'+customize.customize_type_id]['child_'+optionData.selection_id].value.crequired" >{{optionData.selection_name}} {{$t('Maximum')}} {{optionData.is_selection}} {{$t('item select')}}</span>
                                                        <span v-else-if="!$v.selectedCustomisation.customize_type['parent_'+customize.customize_type_id]['child_'+optionData.selection_id].value.valueBetween" >{{optionData.selection_name}} {{$t('Maximum')}} {{optionData.is_selection}} {{$t('item select valid')}}</span>
                                                    </div>
                                                </div>
                                                <!---------Sub Customization End Radio------------>
                                                <!---------Sub Customization Start Checkbox------------>
                                                <div v-else class="custom-checkbox">
                                                    <div class="chld-rdio collapse show"
                                                    :class="{'is-invalid': $v.selectedCustomisation.customize_type['parent_'+customize.customize_type_id]['child_'+optionData.selection_id].value.$error}"
                                                    v-if="optionData.options.length > 0 && selectedCustomisation.customize_type['parent_'+customize.customize_type_id].value == optionData.selection_id">
                                                        <div class="form-group" v-for="(suboptionCheckbox, subindex) in optionData.options" :key="subindex">
                                                            <!-- v-model="productDetail.selected_type[customizeIndex].subcheckbox" -->
                                                            <input class="form-check-input" type="checkbox" :value="suboptionCheckbox.selection_id" :id="'subcheckbox-' + customizeIndex + '-' + index +'-'+ subindex" :name="'subcheckbox_' + customizeIndex+index+'_'+subindex" v-model="$v.selectedCustomisation.customize_type['parent_'+customize.customize_type_id]['child_'+optionData.selection_id].value.$model" @change="checkNestedSub($event)"   />{{suboptionCheckbox.is_selected}}
                                                            <label class="form-check-label" :for="'subcheckbox-' + customizeIndex + '-' + index+'-'+subindex">
                                                                {{ suboptionCheckbox.selection_name }}</label>
                                                            <span class="ch-price"><span v-html="$auth.setting.currency"></span>{{ suboptionCheckbox.selection_price }}</span>
                                                        </div>
                                                    </div>
                                                    <div class="invalid-feedback">
                                                        <span v-if="!$v.selectedCustomisation.customize_type['parent_'+customize.customize_type_id]['child_'+optionData.selection_id].value.crequired" >{{optionData.selection_name}} Maximum {{optionData.is_selection}} item select</span>
                                                        <span v-else-if="!$v.selectedCustomisation.customize_type['parent_'+customize.customize_type_id]['child_'+optionData.selection_id].value.valueBetween" >{{optionData.selection_name}} Maximum {{optionData.is_selection}} item select valid</span>
                                                    </div>
                                                </div>
                                                <!---------Sub Customization End Checkbox------------>
                                            </div>
                                        </div>
                                        <!--Radio Button end-->
                                        <!--CheckBox start-->
                                        <div v-else class="custom-checkbox" 
                                            :class="{'is-invalid': $v.selectedCustomisation.customize_type['parent_'+customize.customize_type_id].value.$error}">
                                            <div  v-for="(optionData, index) in customize.option" :key="index">
                                            <div class="form-group">
                                                <input class="form-check-input" type="checkbox" :value="optionData.selection_id" :id="'checkbox-' + customizeIndex + '-' + index" :name="'checkbox_' + customizeIndex" v-model="$v.selectedCustomisation.customize_type['parent_'+customize.customize_type_id].value.$model" @change="checkNestedSub($event,customize.customize_type_id)"  />
                                                <label class="form-check-label" :for="'checkbox-' + customizeIndex + '-' + index"> {{ optionData.selection_name }} {{ customize.customizeIndex }}</label>
                                                <span class="ch-price"><span v-html="$auth.setting.currency"></span>{{ optionData.selection_price }} <b-icon-arrow-right-circle-fill v-if="optionData.options.length > 0"></b-icon-arrow-right-circle-fill></span>
                                            </div>
                                            <!--------Sub Customization Start Radio------------->
                                            <div v-if="optionData.is_min_selection == '1' && optionData.is_selection == '1' && optionData.options.length > 0 ">
                                                <div class="chld-rdio "
                                                    :class="{'is-invalid': $v.selectedCustomisation.customize_type['parent_'+customize.customize_type_id]['child_'+optionData.selection_id].value.$error}"
                                                    v-if="optionData.options.length > 0 && selectedCustomisation.customize_type['parent_'+customize.customize_type_id].value == optionData.selection_id">
                                                    <div v-for="(subOption,subi) in optionData.options" :key="subi" class="form-check form__radio-group">
                                                        <!-- v-model="productDetail.selected_type[customizeIndex].subradio"  -->
                                                        <input class="form__radio-input" type="radio" :name="'radio-' +index+'-'+ customizeIndex" :id="'radio-' + customizeIndex + '-' +index+'-'+subi" :value="subOption.selection_id" v-model="$v.selectedCustomisation.customize_type['parent_'+customize.customize_type_id]['child_'+optionData.selection_id].value.$model" @change="checkNestedSub($event)" />
                                                        {{subOption.is_selected}}
                                                        <label class="form__label-radio" :for="'radio-' + customizeIndex + '-' +index+'-'+subi"><span class="form__radio-button"></span>{{ subOption.selection_name }}</label>
                                                        <span class="ch-price"><span v-html="$auth.setting.currency"></span>{{ subOption.selection_price }}</span>
                                                    </div>
                                                </div>
                                                <div class="invalid-feedback">
                                                    <span v-if="!$v.selectedCustomisation.customize_type['parent_'+customize.customize_type_id]['child_'+optionData.selection_id].value.crequired" >{{optionData.selection_name}} {{$t('Maximum 1 item select')}}</span>
                                                    <span v-else-if="!$v.selectedCustomisation.customize_type['parent_'+customize.customize_type_id]['child_'+optionData.selection_id].value.valueBetween" >{{optionData.selection_name}} {{$t('Maximum 1 item select valid')}}</span>
                                                </div>
                                            </div>
                                            <!---------Sub Customization End Radio------------>
                                            <!---------Sub Customization Start Checkbox------------>
                                            <div v-else class="custom-checkbox">
                                                <div class="chld-rdio "
                                                    :class="{'is-invalid': $v.selectedCustomisation.customize_type['parent_'+customize.customize_type_id]['child_'+optionData.selection_id].value.$error}"
                                                    v-if="optionData.options.length > 0 
                                                        && selectedCustomisation.customize_type['parent_'+customize.customize_type_id].value 
                                                        && selectedCustomisation.customize_type['parent_'+customize.customize_type_id].value.includes(optionData.selection_id)">
                                                    <div class="form-group" v-for="(suboptionCheckbox, subindex) in optionData.options" :key="subindex">

                                                        <!-- v-model="productDetail.selected_type[customizeIndex].subcheckbox" -->
                                                        <input class="form-check-input" :data-id="customize.customize_type_id+','+optionData.selection_id" type="checkbox" :value="suboptionCheckbox.selection_id" :id="'subcheckbox-' + customizeIndex + '-' + index +'-'+ subindex" v-model="$v.selectedCustomisation.customize_type['parent_'+customize.customize_type_id]['child_'+optionData.selection_id].value.$model" @change="checkNestedSub($event)"   />{{suboptionCheckbox.is_selected}}
                                                        <label class="form-check-label" :for="'subcheckbox-' + customizeIndex + '-' + index+'-'+subindex">
                                                            {{ suboptionCheckbox.selection_name }}</label>
                                                        <span class="ch-price"><span v-html="$auth.setting.currency"></span>{{ suboptionCheckbox.selection_price }}</span>
                                                    </div>
                                                </div>
                                                <div class="invalid-feedback">
                                                    <span v-if="!$v.selectedCustomisation.customize_type['parent_'+customize.customize_type_id]['child_'+optionData.selection_id].value.crequired" >{{optionData.selection_name}} {{$t('Maximum')}} {{optionData.is_selection}} {{$t('item select')}}</span>
                                                    <span v-else-if="!$v.selectedCustomisation.customize_type['parent_'+customize.customize_type_id]['child_'+optionData.selection_id].value.valueBetween" >{{optionData.selection_name}} {{$t('Maximum')}} {{optionData.is_selection}} {{$t('item select valid')}}</span>
                                                </div>
                                            </div>
                                            <!---------Sub Customization End Checkbox------------>
                                            </div>
                                        </div>
                                        <!--CheckBox end-->
                                        <div
                                            class="invalid-feedback"
                                            v-if="$v.selectedCustomisation.customize_type['parent_'+customize.customize_type_id].value.$error"
                                            >
                                            <span v-if="!$v.selectedCustomisation.customize_type['parent_'+customize.customize_type_id].value.crequired">
                                                <span v-if="customize.is_min_selection == 1">{{customize.name}} {{$t('Required Minimun 1 item select')}}</span>
                                                <span v-if="customize.is_min_selection != 1 ">{{customize.name}} {{$t('Required Minimum')}} {{customize.is_min_selection}} {{$t('items')}}</span>
                                            </span>
                                            <span v-else-if="!$v.selectedCustomisation.customize_type['parent_'+customize.customize_type_id].value.valueBetween">
                                                <span v-if="customize.is_min_selection == 1">{{customize.name}} {{$t('Required Minimun 1 item select')}}</span>
                                                <span v-if="customize.is_min_selection != 1">{{customize.name}} {{$t('Required Minimum')}} {{customize.is_selection}} {{$t('items')}}</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="cusines" class="tab-pane tab-box" :class="(productDetail.is_customizable == 0 && (productDetail.item_description || productDetail.custom_details.length != 0))?'active':''">
                            <div class="accordion mt-4" id="accordionExample">
                                <div class="card" v-if="productDetail.item_description">
                                    <div class="card-header" id="headingOne">
                                        <h2 class="mb-0">
                                            <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">Description</button>
                                        </h2>
                                    </div>
                                    <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                                        <div class="card-body">
                                            {{ productDetail.item_description }}
                                        </div>
                                    </div>
                                </div>
                                <div v-if="productDetail.custom_details.length > 0">
                                    <div class="card" v-for="(detail,index) in productDetail.custom_details" :key="index+1">
                                        <div class="card-header" :id="`headingOne-${index}`">
                                            <h2 class="mb-0">
                                            <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" :data-target="`#collapseOne-${index}`" aria-expanded="true" :aria-controls="`collapseOne-${index}`">
                                            {{detail.custom_key}}
                                            </button>
                                            </h2>
                                        </div>
                                        <div  :id="`collapseOne-${index}`" class="collapse" :aria-labelledby="`headingOne-${index}`" data-parent="#accordionExample">
                                            <div class="card-body">
                                            {{detail.custom_value}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </form>                    
                </div>
                <div class="modal-footer" v-if="!closeLabel && pagename != 'qr'" >
                    <div class="col-5" v-if="productDetail && productDetail.is_sold_out == '0' && productDetail.price_type === 'kg'">
                        <form @submit.prevent="addProductToCart" action="#">
                            <input type="text" :placeholder="$t('kg')" class="form-control" :class="{'is-invalid': $v.item_weight.$error}" name="number" v-model="$v.item_weight.$model" @keypress="decNumberOnly" @keyup.enter="addProductToCart">
                        </form>
                    </div>
                    <div class="quantityInc" v-else-if="productDetail && productDetail.is_sold_out == '0'">
                        <div class="number d-flex align-items-center">
                            <span class="minus d-flex justify-content-center align-items-center" @click="decrementItem()">-</span>
                            <input type="text" :value="quantityTotal" readonly />
                            <span class="plus d-flex justify-content-center align-items-center" @click="incrementItem()">+</span>
                        </div>
                    </div>
                    <button v-if="productDetail && productDetail.is_sold_out == '0'" class="add-to-cart btn btn-outline-warning btn-sm" @click="addProductToCart" :style="(productDetail.price_type === 'kg'?'left:auto;width:auto':'')" >
                        {{$t('Add To Cart')}} (<span v-html="$auth.setting.currency"></span> {{(getTotalOptionPrice()*this.quantityTotal).toFixed(2)}})
                    </button>                    
                    <button v-if="productDetail && productDetail.is_sold_out != '0' && !customize_skelloader"  class="sold add-to-cart btn btn-outline-warning btn-sm">Sold Out</button>
                </div>
            </div>
        </div>
    </div>
    <!--------Item Note Start---------->
    <div class="modal fade" id="itemNoteModal" v-if="isItemNote == tempProduct.item_id" data-backdrop="static" data-keyboard="false" style="z-index: 9999;">
      <div class="modal-dialog" role="document">
        <div class="modal-content h-auto w-auto">
          <div class="modal-header">
            <h5 class="modal-title">{{$t('Enter note')}}</h5>
            <button type="button" class="close" data-dismiss="modal" @click="addToCartItem(tempProduct)" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body item_note">
            <textarea v-model="item_note" rows="4" cols="30" autofocus ref='weightInputFocus'></textarea>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn--primary addNotebtn"  @click="noteModalSave()">{{$t('Add')}}</button>
          </div>
        </div>
      </div>
    </div>
    <!--------Item Note End------------>
    <!--Alert Modal start-->
    <AlertModal v-if="isAlert" :DisplayAlertData="AlertData" v-on:modalClose="onModalCloseFun"></AlertModal>
    <!--Alert Modal End-->
</div>
</template>

<script>
import {
    mapGetters,
    mapActions,
    mapMutations
} from "vuex";
import AlertModal from '../components/AlertModal.vue';
import VueSkeletonLoader from 'skeleton-loader-vue';
import {requiredIf,minValue} from 'vuelidate/lib/validators';

export default {
    components: {
        AlertModal,'skeleton-loader-vue': VueSkeletonLoader
    },
    props: ["productId", "restaurantID", "restaurant_on_off","pagename"],
    data() {
        return {
            productDetail: null,
            imgError: "",
            loading: true,
            optionCollection: [],
            selectedCustomisation: {
              customize_type: {}
            },
            selectedCheckbox: [],
            item_weight: "",
            itemTotal: 0,
            quantityTotal: 1,
            closeLabel: false,
            isAlert: false,
            AlertData: {},
            tempProduct: {},
            customize_skelloader: true,
            item_note:'',
            isItemNote:0
        };
    },
    mounted() {
        //this.imgSlider();
        setTimeout(function() {
            window.$("#prod-details-slider").owlCarousel({
            loop:true,
            items: 1,
            pagination: false,
            navigation: true,
            navigationText: ["<", ">"],
            autoPlay: true,
        });
        },1500);
    },
    updated(){
        window.$("#item-popup").on('shown.bs.modal',function() {
            window.$("#prod-details-slider").owlCarousel({
                loop:true,
                items: 1,
                pagination: false,
                navigation: true,
                navigationText: ["<", ">"],
                autoPlay: true,
            });
        });
        window.$("#prod-details-slider").owlCarousel({
            loop:true,
            items: 1,
            pagination: false,
            navigation: true,
            navigationText: ["<", ">"],
            autoPlay: true,
        });
    },
    validations() {
      if(this.productDetail){
        let data = {
          selectedCustomisation:{
            customize_type:{}
          }
        }
        this.productDetail && this.productDetail.customize_type.forEach((item,index)=>{
          let pvalue = null;
          if(!this.selectedCustomisation.customize_type['parent_'+item.customize_type_id]){
            this.selectedCustomisation.customize_type['parent_'+item.customize_type_id] = {value: item.is_selection != 1?[]:null}
          }
          pvalue = this.selectedCustomisation.customize_type['parent_'+item.customize_type_id].value;
          data['selectedCustomisation']['customize_type']['parent_'+item.customize_type_id] = {
              'value':{
                  crequired: ((value) => {
                    return item.is_min_selection > 0 ? (value && value.toString().length > 0 ? true : false) : true;
              } ),
              valueBetween: ((value) => item.is_selection > 1 ?
                value.length >= item.is_min_selection && value.length <= item.is_selection : true)
            }
          }
          this.productDetail.customize_type[index].option.forEach((op, ind) => {
            if(op.is_default == 1){
                let setvalue = this.selectedCustomisation.customize_type['parent_'+op.customize_type_id].value;
                if(setvalue && setvalue != null) {
                    if(typeof setvalue == 'number' || typeof setvalue == 'string') {
                        setvalue = op.selection_id;
                    }else{
                        if(!setvalue.includes(op.selection_id)){
                            setvalue.push(op.selection_id);
                        }
                        // if(setvalue.includes(op.selection_id)){
                        //     console.log("selection_id",op.selection_id);
                        //     setvalue.pop(op.selection_id);
                        // }
                    }
                }
            }
            let temp = this.selectedCustomisation.customize_type['parent_'+item.customize_type_id]['child_'+op.selection_id];
            if(!temp || (pvalue && typeof pvalue == 'object' && (pvalue.length == 0 || !pvalue.includes(op.selection_id)) || pvalue == null || pvalue == '') ){
                if(op.is_selection < op.is_min_selection){
                    this.productDetail.customize_type[index].option[ind].is_selection = op.is_min_selection;
                }
                this.selectedCustomisation.customize_type['parent_'+item.customize_type_id]['child_'+op.selection_id] = {value: op.is_selection != 1?[]:op.is_selection < op.is_min_selection?[]: null}
            }

            if(op.options.length > 0){
                op.options.forEach((itm,key)=>{
                    if(itm.is_default == 1){
                        let setopvalue = this.selectedCustomisation.customize_type['parent_'+op.customize_type_id]['child_'+itm.parent_item_id].value;
                        if(setopvalue != null ){
                            if(typeof setopvalue == 'number' || typeof setopvalue == 'string') {
                                setopvalue = itm.selection_id;
                            }else{
                                setopvalue.push(itm.selection_id);
                            }
                        }
                    }
                })
            }
            data['selectedCustomisation']['customize_type']['parent_'+item.customize_type_id]['child_'+op.selection_id] = {
              'value':{
                crequired: ((value)=> {
                    if(typeof value != 'object'){
                        return (op.is_min_selection > 0 && 
                        (pvalue &&  typeof pvalue == 'object' ? pvalue.includes(op.selection_id) : pvalue == op.selection_id)
                        ? (value && value.toString().length > 0 ? true : false) : true)
                    }
                    return (op.is_min_selection > 0 && 
                    (pvalue && typeof pvalue == 'object' ? pvalue.includes(op.selection_id) : pvalue == op.selection_id)
                    ? (value && value.length > 0 ? true : false) : true)
                }),
                
                valueBetween: ((value) => {
                    if(typeof value != 'object'){
                        return op.is_selection > 1 && 
                        (pvalue && typeof pvalue == 'object' ? pvalue.includes(op.selection_id) : pvalue == op.selection_id) ?
                        value && value.toString().length >= op.is_min_selection &&
                        value && value.toString().length <= op.is_selection : true;
                    }
                    return op.is_selection > 1 && 
                        (pvalue && typeof pvalue == 'object' ? pvalue.includes(op.selection_id) : pvalue == op.selection_id)?
                        value && value.length >= op.is_min_selection &&
                        value && value.length <= op.is_selection : true;
                })
              }
            }
          })
        });
        data['item_weight'] = {
            required: requiredIf(() => this.productDetail.price_type === 'kg'),
            minValue: minValue(0.1)
        }
        return data;
      }else{
        return {}
      }
    },
    computed: {
        ...mapGetters("product", ["cart"]),
    },

    methods: {
        ...mapActions("product", ["addProductToCart", "getProductDetails"]),
        ...mapMutations("product", ["clearCartItems"]),
        
        imgSlider(){
            window.$("#item-popup").on('shown.bs.modal',function() {
                setTimeout(function() {
                    window.$(".owl-carousel").owlCarousel({
                    loop:true,
                    items: 1,
                    pagination: false,
                    navigation: true,
                    navigationText: ["<", ">"],
                    autoPlay: true,
                });
                },1000)
            });
        },

        openProductModel: function (itemId) {
            this.itemTotal = 0;
            this.quantityTotal = 1;
            this.loading = true;
            this.customize_skelloader = true;
            this.getProductDetails({
                vendor_id: this.$auth.getVendorId(),
                is_langauge: this.$store.state.lang.locale,
                latitude: localStorage.getItem("latitude"),
                starttime: "",
                longitude: localStorage.getItem("longitude"),
                restaurant_id: this.restaurantID,
                date: "",
                item_id: itemId,
            }).then((data) => {
                if (data.code == 200) {
                    this.loading = false;
                    if (data.Result[0] != undefined && data.Result[0].length != 0) {
                        this.customize_skelloader = false;
                        this.productDetail = {
                            ...data.Result[0],
                            item_weight: '',
                            selected_type: data.Result[0].customize_type.map((p) => ({
                                customize_type_id: p.customize_type_id,
                                max_selection: parseInt(p.is_selection),
                                min_selection: parseInt(p.is_min_selection),
                                selection_name: p.name,

                                ...(p.is_selection != 1 ? {
                                    option: p.option.filter(
                                        (q) =>
                                        q.is_default == "1"
                                    )
                                } : {
                                    option: []
                                }),
                                ...(p.is_selection == 1 ? {
                                    roption: p.option.find(
                                        (q) => q.is_default == "1"
                                    )
                                } : {
                                    roption: undefined
                                }),

                            })),
                        };
                    }
                    //this.imgSlider();
                }
            });
        },

        //Display Total Amount Of item cart
        getTotalOptionPrice() {
            let radioTotal = 0;
            let checkboxTotal = 0;
            let pvalue = null;
            this.productDetail.customize_type.forEach((itm,index)=>{
                let parentId = 'parent_'+itm.customize_type_id;
                if(this.selectedCustomisation.customize_type['parent_'+itm.customize_type_id]){
                    pvalue = this.selectedCustomisation.customize_type['parent_'+itm.customize_type_id].value;
                }else{
                    pvalue = null;
                }
                if(itm.option){
                    itm.option.forEach((itmoption,key)=>{
                        if(pvalue && pvalue != null && pvalue.includes(itmoption.selection_id)){
                            radioTotal += itmoption.selection_price;
                            if(itmoption.options) {
                                let temp = null;
                                itmoption.options.forEach((option,okey)=>{
                                    temp = this.selectedCustomisation.customize_type['parent_'+itm.customize_type_id]['child_'+option.parent_item_id].value;
                                    if(temp && typeof temp == 'number' && temp == option.selection_id){
                                        radioTotal += option.selection_price;
                                    }
                                    if(temp && typeof temp == 'object' && temp.includes(option.selection_id)){
                                        radioTotal += option.selection_price;
                                    }
                                })
                            }
                        }
                    })
                }
            })
            return this.productDetail.price + radioTotal + checkboxTotal;
        },

        onModalCloseFun(value) {
            this.isAlert = false;
            if (value == 'yes') {
                this.$store.commit("product/clearCartItems");
                this.addToCartItem(this.tempProduct);
            }
        },

        

        addProductToCart() {
            this.isItemNote = this.productDetail.item_id;
            this.$v.$touch();
            // if(this.$v.selectedCustomisation.customize_type.$invalid){
            if(this.$v.$invalid){
                return false;
            }
            let pvalue = null;
            let arraySelectCustoizeID = [];
            let arraySelectCustoizePrice = [];
            let selected_type = [];
            this.productDetail.customize_type.forEach((itm,index)=>{
                let parentId = 'parent_'+itm.customize_type_id;
                pvalue = this.selectedCustomisation.customize_type['parent_'+itm.customize_type_id].value;
                if(itm.option){
                    itm.option.forEach((itmoption,key)=>{
                        if(pvalue && pvalue != null && pvalue.includes(itmoption.selection_id)){
                            arraySelectCustoizeID.push(itmoption.selection_id);
                            arraySelectCustoizePrice.push(itmoption.selection_price);
                            selected_type.push(itmoption.selection_name);

                            if(itmoption.options) {
                                let temp = null;
                                let subOptionCustoizeID = [];
                                let subOptionCustoizePrice = [];
                                itmoption.options.forEach((option,okey)=>{
                                    temp = this.selectedCustomisation.customize_type['parent_'+itm.customize_type_id]['child_'+option.parent_item_id].value;
                                    if(temp && typeof temp == 'number' && temp == option.selection_id){
                                        arraySelectCustoizeID.push(option.selection_id);
                                        arraySelectCustoizePrice.push(option.selection_price);
                                        selected_type.push(option.selection_name);
                                    }
                                    if(temp && typeof temp == 'object' && temp.includes(option.selection_id)){
                                        arraySelectCustoizeID.push(option.selection_id);
                                        arraySelectCustoizePrice.push(option.selection_price);
                                        selected_type.push(option.selection_name);
                                    }
                                });
                            }
                            
                        }
                    })
                }
            })
            let savemrp = 0;
            if(this.productDetail.price < this.productDetail.mrp){
                savemrp = this.productDetail.mrp - this.productDetail.price;                
            }
            var AddToCartItemArray = {
                restaurant_id: this.restaurantID,
                item_id: parseInt(this.productDetail.item_id),
                item_name: this.productDetail.item_name,
                price: this.getTotalOptionPrice(),
                quantity: this.quantityTotal,
                itemTotalQuantity: this.productDetail.quantity,
                selected_type: selected_type,
                selectCustoizeID: arraySelectCustoizeID,
                selectCustoizePrice: arraySelectCustoizePrice,
                item_type: this.productDetail.item_type,
                item_price: this.productDetail.price,
                image:this.productDetail.image,
                saveprice:savemrp,
                price_type:this.productDetail.price_type,
                item_weight:parseFloat(this.item_weight),
                item_note:''
            };            
            var checkRestarant = this.cart.find((ele) => (ele.restaurant_id == this.restaurantID));
            this.tempProduct = AddToCartItemArray;            
            if (this.cart.length) {                
                if (checkRestarant) {
                    window.$("#item-popup").modal("hide");
                    setTimeout(() => {                    
                        window.$("#itemNoteModal").modal("show");
                    }, 500);
                    //this.addToCartItem(AddToCartItemArray);
                } else {                    
                    this.AlertData = {
                        displayMessage: this.$t('Your cart contain items from another ')+ this.$t(this.$auth && this.$auth.getRestaurantName()) +this.$t('Do you wish to clear cart and start a new order here?'),
                        isSetClass: 'prdct-modal-cls'
                    };
                    this.isAlert = true;
                    this.tempProduct = AddToCartItemArray;
                    window.$("#item-popup").modal("hide");
                    window.$(this.$refs.AlertModal).modal();
                }
            } else {                
                window.$("#item-popup").modal("hide");
                setTimeout(() => {                    
                    window.$("#itemNoteModal").modal("show");
                }, 500);
                // this.addToCartItem(AddToCartItemArray);
                
            }
        },

        addToCartItem(product) {
            this.isItemNote = '';
            this.item_note = '';
            this.item_weight = ''
            const cartItemIndex = this.cart.findIndex(item => parseInt(item.item_id) === parseInt(product.item_id));
            let totalQnt = 0;
            if(cartItemIndex != undefined && cartItemIndex != -1 ) {
                var itemTotalQuantity = this.cart[cartItemIndex].quantity;
                totalQnt = this.cart[cartItemIndex].quantity + product.quantity;
            } else {
                totalQnt = product.quantity;
            }
            if(totalQnt <= product.itemTotalQuantity){
                this.$store.dispatch("product/addProductToCart", product);
                window.$("#item-popup").modal("hide");
            }            
        },
        
        checkNested(e, cust_id){
          this.selectedCustomisation.customize_type = {
            ...this.selectedCustomisation.customize_type,
            ['parent_'+cust_id]:{value : e.target.value}  
          }
        },

        checkNestedSub(e, cust_id){
            let types = Object.keys(this.selectedCustomisation.customize_type);
             this.selectedCustomisation.customize_type = {
                ...this.selectedCustomisation.customize_type,
                /* ...(types.reduce((acc,ire) => {
                    acc[ire] = {...this.selectedCustomisation.customize_type[ire]};
                    return acc;
                },{})) */
            }
        },

        incrementItem() {
            if (this.productDetail.quantity > this.quantityTotal) {
                this.quantityTotal++;
            }
        },

        decrementItem() {
            if (this.quantityTotal != 1) {
                this.quantityTotal--;
            }
        },

        checkInCart(itemId) {
            var checkItem = this.cart.find((ele) => ele.item_id == itemId);
            if (checkItem) {
                return false;
            } else {
                return true;
            }
        },

        onImgError() {
            this.imgError = true;
        },

        decNumberOnly(evt) {
            let val1 = evt.target.value;
            if(val1.length > 5){
                evt.preventDefault();
            }
            if (!(evt.keyCode == 46 || (evt.keyCode >= 48 && evt.keyCode <= 57))) {
                evt.preventDefault();
            }
            let partlen = val1.split(".").length - 1;
            if (evt.keyCode == 46 && partlen > 0) {
                evt.preventDefault();
            }
        },

        noteModalSave(){
            window.$("#itemNoteModal").modal("hide");            
            this.tempProduct.item_note = this.item_note;
            this.addToCartItem(this.tempProduct);
        },
    },
};
</script>
<style scoped>
.btn--primary, .btn--primary:hover  {
  background: rgba(var(--theme-primary-button-color));
  color: #fff;
}

@media all and (max-width:767px){
    .cust-prdt-table .modal-content{
        width: 95%;
    }
}
</style>